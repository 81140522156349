import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setUserData } from "redux/user/actions";
import { setActiveStudyId } from "redux/study/actions";
import styles from "./user_entry_point.module.css";
import ctms_logo from "assets/act_global_logo.png";

import {
  useNavigate,
  useLocation,
  createSearchParams,
  useSearchParams,
} from "react-router-dom";
import { setAuthState } from "redux/auth/actions";

import SignIn from "views/signin";
import BaseRoute from "views/base_route";
import { checkHasActiveSignIn } from "util/auth";
import { useResetTimer } from "timer";

export default function UserEntryPoint() {
  const navigate = useNavigate();
  const authState = useSelector((state) => state.auth.authState);
  const default_study_id = useSelector((state) => state.study.default_study_id);
  // const [authState, setAuthState] = useState("loading");
  const dispatch = useDispatch();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const reset_logout_timer = useResetTimer();

  useEffect(() => {
    checkHasActiveSignIn()
      .then((ret) => {
        reset_logout_timer();
        dispatch(setAuthState(true));
        if (ret && ret.user_data) {
          dispatch(setUserData(ret.user_data));
        }

        let study_id_pref = null;
        if (ret.user_data && ret.user_data.preferences) {
          let study_id_pref = ret.user_data.preferences.find(
            (p) => p.name === "default_study_id",
          );
          if (study_id_pref) {
            dispatch(setActiveStudyId(parseInt(study_id_pref.value)));
          } else {
            dispatch(setActiveStudyId(1));
          }
        }

        if (!study_id_pref) {
          study_id_pref = { value: 1 };
        }

        let study = ret.user_data.studies.find(
          (s) => s.id === study_id_pref.value,
        );
        if (!study || !study.activated) {
          navigate({ pathname: "/user_profile" });
          return;
        }

        if (location.pathname === "/") {
          if (ret.user_data && ret.user_data.preferences) {
            let search = createSearchParams({
              sid: study_id_pref.value,
            }).toString();

            navigate({ pathname: "/subjects", search });
          }
        }
      })
      .catch(() => {
        dispatch(setAuthState(false));
        if (window.location.pathname !== "/") {
          window.location.assign("/");
        }
      });
  }, [authState]);

  if (authState === "loading") {
    return (
      <div className={styles.loading_wrapper}>
        <img src={ctms_logo} className={styles.logo} />
      </div>
    );
  } else if (authState === true) {
    return <BaseRoute />;
  } else {
    return <SignIn />;
  }
}
